<template>
  <div class="card">
    <div class="card-body">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
      <div class="row justify-content-md-center">
        <div class="col-12">
          <form-wizard
            title=""
            subtitle=""
            color="#918158"
            nextButtonText='Avançar'
            stepSize="xs"
            :validateOnBack="true"
            v-on:on-validate="onValidateStep"
          >
            <!--   Carga     -->
            <tab-content :title="$t('form.proposal.step.carga')" :before-change="()=>validateStep('cargo')" :on-validate="onValidateStep" icon="fa fa-check" >
              <div class="row">
                <!--  Form Content      -->
                <div class="col-lg-7">
                  <div class="row">
                    <!-- Content -->
                    <div class="col-12 col-md-12">
                      <form-cargo v-model="formData" ref="cargo" v-on:onLoading="onLoading">
                        <template slot="title">
                          <h2 class="title">{{ $t('form.proposal.step.detalhes_carga')}}</h2>
                        </template>
                      </form-cargo>
                    </div>
                  </div>
                </div>
                <!--   Summary   -->
                <div class="col-lg-5">
                  <proposal-summary :proposal="formData" @on-validate="mergePartialModels" />
                </div>
              </div>
            </tab-content>

            <!--   Exportador     -->
            <tab-content v-if="!isComercialInvoice" :title="$t('form.proposal.step.exportador')" :before-change="()=>validateStep('exportador')" icon="fa fa-check" >
                <div class="row">
                  <!--  Form Content      -->
                  <div class="col-lg-7">
                    <div class="row">
                      <!-- Content -->
                      <div class="col-12 col-md-12">
                          <form-person v-model="formData.exportador" :proposalId="formData.id" :personType="'exportador'" :country-list="countryList" :person-list="personList" ref="exportador" v-on:onLoading="onLoading" v-on:onPersonUpdated="onPersonUpdated">
                            <template slot="title">
                              <h2 class="title">{{ $t('form.proposal.step.exportador')}}</h2>
                            </template>
                          </form-person>
                      </div>
                    </div>
                  </div>
                  <!--   Summary   -->
                  <div class="col-lg-5">
                    <proposal-summary :proposal="formData" @on-validate="mergePartialModels" v-on:onLoading="onLoading" />
                  </div>
                </div>
            </tab-content>

            <!--   Importador     -->
            <tab-content v-if="!isComercialInvoice" :title="$t('form.proposal.step.importador')" :before-change="()=>validateStep('importador')" icon="fa fa-check" >
              <div class="row">
                <!--  Form Content      -->
                <div class="col-lg-7">
                  <div class="row">
                    <!-- Content -->
                    <div class="col-12 col-md-12">
                      <!-- Sort Order -->
                      <div class="row">
                        <div class="col-12">
                          <form-person v-model="formData.importador" :allowEmpty="formData.tipo_operacao === 1" :proposalId="formData.id" :personType="'importador'" :country-list="countryList" :person-list="personList" ref="importador" v-on:onLoading="onLoading" v-on:onPersonUpdated="onPersonUpdated">
                            <template slot="title">
                              <h2 class="title">{{ $t('form.proposal.step.importador')}}</h2>
                            </template>
                          </form-person>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Summary   -->
                <div class="col-lg-5">
                  <proposal-summary :proposal="formData"  />
                </div>
              </div>
            </tab-content>

            <!--   Notify     -->
            <tab-content v-if="!isComercialInvoice" :title="$t('form.proposal.step.notify')" :before-change="()=>validateStep('notify')" icon="fa fa-check" >
              <div class="row">
                <!--  Form Content      -->
                <div class="col-lg-7">
                  <div class="row">
                    <!-- Content -->
                    <div class="col-12 col-md-12">
                      <!-- Sort Order -->
                      <div class="row">
                        <div class="col-12">
                          <form-person v-model="formData.notify"  :proposalId="formData.id" :personType="'notify'" :country-list="countryList" :person-list="personList" ref="notify" v-on:onLoading="onLoading" v-on:onPersonUpdated="onPersonUpdated" >
                            <template slot="title">
                              <h2 class="title">{{ $t('form.proposal.step.notify')}} <span>
                                  <i class="ft-alert-circle"></i><md-tooltip md-direction="right">{{ $t('form.proposal.step.notify_helper')}}</md-tooltip>
                                </span>
                              </h2>
                            </template>
                          </form-person>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Summary   -->
                <div class="col-lg-5">
                  <proposal-summary :proposal="formData"  />
                </div>
              </div>
            </tab-content>

            <!--   Adicionais     -->
            <tab-content :title="$t('form.proposal.step.adicionais')" :before-change="()=>validateStep('adicionais')" icon="fa fa-check" >
              <div class="row">
                <!--  Form Content      -->
                <div class="col-lg-7">
                  <!-- Sort Order -->
                  <div class="row">
                    <div class="col-12">
                      <form-up-sell :proposalId="formData.id" :moedaFrete="moedaFrete"  v-model="formData.adicionais" ref="adicionais" v-on:onLoading="onLoading">
                        <template slot="title">
                          <h2 class="title">{{ $t('form.proposal.step.adicionais')}}</h2>
                          <h3 class="subtitle sub-extra">{{ $t('form.proposal.step.adicionais_descricao')}}</h3>
                        </template>
                      </form-up-sell>
                    </div>
                  </div>
                </div>
                <!--   Summary   -->
                <div class="col-lg-5 sumary-extra">
                  <!-- <div class="row"> -->
                    <proposal-summary :proposal="formData" />
                  <!-- </div> -->
                </div>
              </div>
            </tab-content>

            <!--   Confirmação     -->
            <tab-content :title="$t('form.proposal.step.confirmacao')" icon="fa fa-check" >
              <div class="row">
                <!--  Form Content      -->
                <div class="col-12 col-md12">
                  <div class="row">
                    <!-- Content -->
                    <div class="col-12 col-md-12">
                      <!-- Sort Order -->
                      <div class="row">
                        <div class="col-12">
                          <form-confirm v-model="formData" ref="confirmacao" :moedaFrete="moedaFrete">
                            <template slot="title">
                              <h2 class="title">{{ $t('form.proposal.step.confirmacao')}}</h2>
                              <h3 class="subtitle">{{ $t('form.proposal.step.confirmacao_descricao')}}</h3>
                            </template>
                          </form-confirm>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>

            <!-- Action Buttons -->
            <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left">
                <button  v-if="props.activeTabIndex > 0" v-show="props.activeTabIndex > 0"  v-on:click="props.prevTab()"  class="btn btn-sm btn-secondary btn-fill px-4 py-2 mr-4">
                  Voltar
                </button>
                <button v-if="!props.isLastStep" v-on:click="onSaveDraft" class="btn btn-sm btn-secondary btn-fill px-4 py-2 mr-4" href="javascript:;"
                        >Salvar como rascunho, e continuar mais tarde
                </button>
                <button v-if="!props.isLastStep" v-on:click="props.nextTab()" class="btn btn-sm btn-primary btn-fill px-4 py-2" href="javascript:;"
                  :disabled="!formData.carga.segura_naopessoal">Avançar
                </button>
                <button v-else v-on:click="onSave()" class="btn btn-sm btn-primary btn-fill px-4 py-2">
                  {{ 'Solicitar Reserva' }}
                </button>
              </div>
              <div class="wizard-footer-right">
              </div>
            </template>
          </form-wizard>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
// Components

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

// Form
import ProposalSummary from './ProposalSummary'
import FormPerson from './FormPerson'
import FormUpSell from './FormUpSell'
import FormCargo from './FormCargo'
import FormConfirm from './FormConfirm'

// Services
import EquipmentService from '@/services/EquipmentService'
import TariffService from '@/services/TariffService'
import AddressService from '@/services/AddressService'
import ProposalService from '@/services/ProposalService'

// Modal
import ContactModal from './ContactModal'

export default {
  name: 'ProposalList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.proposal') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: true,
      fullPage: true,
      personList: [],
      countryList: [],
      equipmentList: [],
      sortOrderList: [
        {
          id: 1,
          nome: 'Preço (Menor Valor)'
        },
        {
          id: 2,
          nome: 'Preço (Maior Valor)'
        }
      ],
      // filterData
      filterData: {
        operation: {
          id: 2,
          nome: 'Importação'
        },
        modality: {
          id: 2,
          nome: 'Marítimo - FCL'
        },
        commoditie: {
          id: null,
          nome: null
        },
        origin: {
          id: null,
          nome: null
        },
        destination: {
          id: null,
          nome: null
        },
        estimatedDate: null,
        equipments: [],
        showShipowner: null,
        sortBy: {
          id: 1,
          nome: 'Preço (Menor Valor)'
        }
      },
      // FormData
      formData: {
        arquivos: [],
        servico_com_transbordo: null,
        cliente: {
          id: null,
          nome: null,
          cnpjVatnumber: null
        },
        comercial_invoice: false,
        id_tarifario_maritimo: null,
        previsao_embarque: null,
        previsao_desembarque: null,
        valor_frete: null,
        transit_time: null,
        tipo_operacao: null,
        tipo_carga: null,
        incoterm: null,
        modalidade_pagamento: null,
        modalidade_processo: null,
        rota: null,
        origem: null,
        destino: null,
        equipamentos: [],
        referencia: null,
        cupom: {
          id: null,
          moeda: null,
          valor: null
        },
        referencia_cliente: null,
        carga: {
          mercadoria: null,
          ncm: null,
          descricao: null,
          quantidade: null,
          tipo_volume: null,
          peso_bruto: null,
          cmb: null,
          segura_naopessoal: false
        },
        exportador: {
          id: null,
          cnpj_vatnumber: null,
          nome: null,
          pais: null,
          estado: null,
          cidade: null,
          ufExterior: null,
          municipioExterior: null,
          cep: null,
          logradouro: null,
          email: null,
          telefone: null,
          favorito: null,
          contatos: [
            {
              nome: null,
              email: null,
              fone: null
            }
          ]
        },
        importador: {
          id: null,
          cnpj_vatnumber: null,
          nome: null,
          pais: null,
          estado: null,
          cidade: null,
          ufExterior: null,
          municipioExterior: null,
          cep: null,
          logradouro: null,
          email: null,
          telefone: null,
          favorito: null,
          contatos: [
            {
              nome: null,
              email: null,
              fone: null
            }
          ]
        },
        notify: {
          id: null,
          cnpj_vatnumber: null,
          nome: null,
          pais: null,
          estado: null,
          cidade: null,
          ufExterior: null,
          municipioExterior: null,
          cep: null,
          logradouro: null,
          email: null,
          telefone: null,
          favorito: null,
          contatos: [
            {
              nome: null,
              email: null,
              fone: null
            }
          ]
        },
        adicionais: {
          seguro: {
            freteMercadoria: false,
            impostos: false,
            outras_despesas: false,
            valor_mercadoria: 0.0,
            valor_frete: 0.0,
            valor_seguro: 0.0
          },
          complementar: ''
        },
        taxas: [],
        total: []
      },
      tariffOptions: []
    }
  },
  components: {
    ProposalSummary,
    FormPerson,
    FormUpSell,
    FormCargo,
    FormConfirm,
    FormWizard,
    TabContent,
    Loading
  },
  // Apply filters to local filter
  created () {
    let _this = this
    /** @TODO: Load basilares data */
    _this.isLoading = true

    ProposalService.getProposal(_this.$route.params.id).then((res) => {
      _this.formData = res.data.data
      // Format Numbers
      _this.formData.carga.peso_bruto = this.$util.formatNumber(_this.formData.carga.peso_bruto)
      _this.formData.carga.cbm = this.$util.formatNumber(_this.formData.carga.cbm)
      _this.formData.adicionais.seguro.valor_mercadoria = this.$util.formatNumber(_this.formData.adicionais.seguro.valor_mercadoria)
      _this.formData.adicionais.seguro.valor_seguro = this.$util.formatNumber(_this.formData.adicionais.seguro.valor_seguro)
      // _this.formData.referencia = res.data.data.referencia
      // _this.formData.id = res.data.data.id
      // Load basilares data
      AddressService.getCountries({ per_page: 500 }).then(res => {
        _this.countryList = res.data.data

        let filters = {
          'per_page': 200,
          'searchTerm': ''
        }

        ProposalService.getPersons(filters).then((res) => {
          _this.personList = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }).finally(() => {
        _this.isLoading = false
      })
    }).catch(() => {
      _this.isLoading = false
      setTimeout(function () {
        _this.$router.push({ name: 'ProposalIndex' })
      }, 1000)
    })
  },
  computed: {
    isComercialInvoice () {
      if (this.formData.comercial_invoice === true) {
        return true
      }

      return false
    },
    isCupomAdded () {
      let isAdded = false

      if (this.formData.cupom && this.formData.cupom.id !== null) {
        isAdded = true
      }

      return isAdded
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    moedaFrete () {
      let moeda = ''

      for (let i in this.formData.taxas) {
        let taxa = this.formData.taxas[i]
        if (taxa.tipo === 1) {
          moeda = taxa.moeda
          break
        }
      }

      return moeda
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    isSuperAdmin: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  },
  methods: {
    onPersonUpdated (type) {
      let _this = this

      if (type === 'exportador') {
        if (_this.formData.importador.id && _this.formData.importador.id === _this.formData.exportador.id) {
          _this.formData.importador = Object.assign({}, _this.formData.exportador)
        }

        if (_this.formData.notify.id && _this.formData.notify.id === _this.formData.exportador.id) {
          _this.formData.notify = Object.assign({}, _this.formData.exportador)
        }
      } else if (type === 'importador') {
        if (_this.formData.exportador.id && _this.formData.exportador.id === _this.formData.importador.id) {
          _this.formData.exportador = Object.assign({}, _this.formData.importador)
        }

        if (_this.formData.notify.id && _this.formData.notify.id === _this.formData.importador.id) {
          _this.formData.notify = Object.assign({}, _this.formData.importador)
        }
      } else if (type === 'notify') {
        if (_this.formData.exportador.id && _this.formData.exportador.id === _this.formData.notify.id) {
          _this.formData.exportador = Object.assign({}, _this.formData.notify)
        }

        if (_this.formData.importador.id && _this.formData.importador.id === _this.formData.notify.id) {
          _this.formData.importador = Object.assign({}, _this.formData.notify)
        }
      }
    },
    getDefaultContacts () {
      let contatos = []

      if (!this.formData.comercial_invoice && this.formData.exportador.id !== null) {
        contatos = this.loadContacts(contatos, this.formData.exportador)
      }

      if (!this.formData.comercial_invoice && this.formData.importador.id !== null) {
        contatos = this.loadContacts(contatos, this.formData.importador)
      }

      if (!this.formData.comercial_invoice && this.formData.notify.id !== null) {
        contatos = this.loadContacts(contatos, this.formData.notify)
      }

      return contatos
    },
    loadContacts (contacts, person) {
      for (let i in person.contatos) {
        let contact = person.contatos[i]

        if (!this.hasContact(contacts, contact.email)) {
          contacts.push({
            nome: contact.nome,
            email: contact.email,
            send: true
          })
        }
      }

      return contacts
    },
    hasContact (contacts, contactMail) {
      let added = contacts.find((e) => {
        return e.email === contactMail
      })

      return added !== undefined
    },
    onCloseAddContactsModal () {
      this.$router.push({ name: 'ProposalIndex' })
    },
    onLoading (isLoading) {
      this.isLoading = isLoading
    },
    onSaveDraft () {
      this.$router.push({ name: 'ProposalIndex' })
    },
    onValidateStep (isValid, index) {
      if (isValid && index >= 0 && index <= 2) {
        this.isLoading = true
        ProposalService.getPersons({ 'per_page': 200, 'searchTerm': '' }).then((res) => {
          this.personList = res.data.data
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    validateStep (name) {
      let refToValidate = this.$refs[name]

      return refToValidate.validate()
    },
    mergePartialModels (model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.formData = Object.assign({}, this.formData, model)
      }
    },
    selectSortOrder (sortBy) {
      this.filterData.sortBy = sortBy

      this.onSearch(this.value)
    },
    equipmentLoadOptions () {
      let _this = this

      let promise = new Promise((resolve, reject) => {
        let filters = {
          'modality': _this.filterData.modality.id,
          'quotations': true
        }

        EquipmentService.getEquipments(filters).then(res => {
          let items = []

          for (let i in res.data.data) {
            let item = res.data.data[i]

            item.quantity = 0

            for (let sI in _this.filterData.equipments) {
              let selected = _this.filterData.equipments[sI]

              if (selected.id === item.id) {
                item.quantity = selected.quantity
                break
              }
            }
            items.push(item)
          }
          resolve(items)
        }).catch((error) => {
          reject(error)
        })
      })

      return promise
    },
    onSearch (value) {
      let _this = this
      _this.isLoading = true

      let filters = {
        operation: _this.filterData.operation.id,
        modality: _this.filterData.modality.id,
        commoditie: _this.filterData.commoditie.id,
        origin: _this.filterData.origin.id,
        destination: _this.filterData.destination.id,
        estimatedDate: _this.filterData.estimatedDate,
        sortBy: _this.filterData.sortBy.id,
        equipments: _this.filterData.equipments.map((equipment) => {
          return {
            id: equipment.id,
            quantity: equipment.quantity
          }
        })
      }

      TariffService.getTariffs(filters).then((res) => {
        _this.tariffOptions = res.data.data
      }).finally(() => {
        _this.isLoading = false
      })
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'ProposalIndex' })
    },
    onSave () {
      let _this = this

      _this.isLoading = true

      let taxas = _this.formData.taxas.map(x => ({
        tipo: x.tipo,
        quantidade: x.quantidade,
        descricao: x.descricao,
        valorUnitario: x.valor_unitario,
        valorMinimo: x.valor_pagamento_minimo,
        moeda: x.moeda,
        unidade: x.unidade
      }))

      let contatos = _this.getDefaultContacts()

      let data = {
        modalidadeProcesso: _this.formData.modalidade_processo,
        tipoOperacao: _this.formData.tipo_operacao,
        comercialInvoice: _this.formData.comercial_invoice,
        tipoCarga: _this.formData.tipo_carga,
        tipoServico: null,
        rota: _this.formData.rota,
        transitTime: _this.formData.transit_time,
        referencia: _this.formData.referencia,
        referenciaCliente: _this.formData.referencia_cliente,
        dataPrevisaoEmbarque: _this.formData.previsao_embarque,
        // seguro: _this.formData.adicionais.seguro,
        idCliente: _this.formData.cliente.id,
        idMercadoria: (!_this.formData.comercial_invoice && _this.formData.mercadoria) ? _this.formData.mercadoria.id : null,
        idexportador: (!_this.formData.comercial_invoice && _this.formData.exportador) ? _this.formData.exportador.id : null,
        idimportador: (!_this.formData.comercial_invoice && _this.formData.importador) ? _this.formData.importador.id : null,
        idnotify: (!_this.formData.comercial_invoice && _this.formData.notify) ? _this.formData.notify.id : null,
        idcontratoFrete: null,
        idtipoProcesso: null,
        idincoterm: _this.formData.incoterm,
        iddestino: _this.formData.destino.id,
        idorigem: _this.formData.origem.id,
        idtarifarioMaritimo: _this.formData.id_tarifario_maritimo,
        complementar: _this.formData.adicionais.complementar,
        carga: {
          descricao: (!_this.formData.comercial_invoice) ? _this.formData.carga.descricao : null,
          quantidadeVolumes: (!_this.formData.comercial_invoice) ? _this.formData.carga.quantidade : null,
          pesoBruto: (!_this.formData.comercial_invoice) ? _this.$util.getNumberFromFormated(_this.formData.carga.peso_bruto) : null,
          cbm: (!_this.formData.comercial_invoice) ? _this.$util.getNumberFromFormated(_this.formData.carga.cbm) : null,
          valorMercadoria: _this.$util.getNumberFromFormated(_this.formData.adicionais.seguro.valor_mercadoria),
          seguroFreteMercadoria: _this.formData.adicionais.seguro.freteMercadoria,
          seguroImpostos: _this.formData.adicionais.seguro.impostos,
          seguroOutrasDespesas: _this.formData.adicionais.seguro.outras_despesas,
          valorSeguro: _this.formData.adicionais.seguro.valor_seguro,
          importanciaSegurada: null,
          percentualSeguro: null,
          cargaNaoPerigosa: _this.formData.carga.segura_naopessoal,
          idncm: (!_this.formData.comercial_invoice) ? _this.formData.carga.ncm.id : null,
          idtipoEmbalagem: (!_this.formData.comercial_invoice) ? _this.formData.carga.tipo_volume.id : null,
          idmoedaMercadoria: null
        },
        equipamentos: _this.formData.equipamentos.map(x => ({
          freeTimeOrigem: x.free_time_origem,
          freeTimeDestino: x.free_time_destino,
          idequipamentoMaritimo: x.id,
          quantidade: x.quantidade
        })),
        taxas: taxas,
        contatos: contatos
      }

      if (_this.formData.comercial_invoice === true) {
        data.arquivos = _this.formData.arquivos
      } else {
        data.arquivos = []
      }

      if (_this.formData.cupom && _this.formData.cupom.id !== null) {
        data.cupom = {
          idcupom: _this.formData.cupom.id
        }
      }

      if (_this.isCupomAdded) {
        // Verifica se o cupom é válido
        ProposalService.checkCupom(_this.formData.id, data.cupom.idcupom).then(res => {
          if (!res.data.validCupom) {
            _this.formData.cupom = {
              id: null,
              moeda: null,
              valor: null
            }

            // Cupom já adicionado em uma pré-reserva finalizada
            _this.$alertSwal.modalAlert('', 'Cupom já utilizado em uma pré-reserva,<br/> e foi removido.<br/> Para prosseguir basta clicar em <br/>"Solicitar Reserva novamente"', 'warning', null, {
              showConfirmButton: true
            }).then((res) => {
              _this.isLoading = false
              setTimeout(function () {
                _this.$util.scrollToTop()
              }, 500)
            })
          } else if (res.data.cupomAlert === 'cupom in a draft') {
            // Cupom adicionado em um rascunho ou pré-reserva em andamento
            _this.$alertSwal.modalAlert('', 'Cupom já utilizado em outro rascunho, deseja utilizá-lo nessa Pré-Reserva?', 'warning', null, {
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: 'Utilizar',
              cancelButtonText: 'Não utilizar'
            }).then((result) => {
              if (result.hasOwnProperty('dismiss')) {
                _this.isLoading = false

                _this.formData.cupom = {
                  id: null,
                  moeda: null,
                  valor: null
                }

                _this.$alertSwal.modalAlert('', 'Cupom foi removido.<br/> Para prosseguir basta clicar em <br/>"Solicitar Reserva novamente"', 'warning', null, {
                  showConfirmButton: true
                }).then(() => {
                  setTimeout(function () {
                    _this.$util.scrollToTop()
                  }, 500)
                })
              } else {
                _this.saveProposal(data)
              }
            })
          }
        })
      } else {
        _this.saveProposal(data)
      }
    },
    saveProposal (data) {
      let _this = this
      ProposalService.putProposal(_this.formData.id, data).then(response => {
        global.instanceApp.$Cookie.remove('_proposal_last_search_')
        _this.isLoading = false
        this.$modal.show(ContactModal, {
          proposal_id: response.data.data.id,
          showSuccess: true
        },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '48%',
          height: 'auto'
        },
        {
          'closed': _this.onCloseAddContactsModal
        })
      }).catch(() => {
        _this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
}

.sub-extra {
  line-height: 1.5;
}

@media (max-width: 991px) {
  .sumary-extra {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
